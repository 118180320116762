<template>
  <v-card v-if="panelType === 'Panel'" max-width="500" class="mx-auto" style="border-radius: 0; border-right: 1px solid rgba(0, 0, 0, 0.12);">
    <v-toolbar color="primary" v-if="false" :height="config.filterHeaderHeight">
      <v-toolbar-title class="tertiary--text">{{ $t('filterHeader') }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-expansion-panels v-model="activePanel" multiple flat accordion hover tile>
      <v-expansion-panel v-for="(filter,i) in filters" :key="i">
        <v-expansion-panel-header>{{ groupHeaderName(filter) }}</v-expansion-panel-header>
        <v-expansion-panel-content class="text-truncate text-no-wrap">
          <template v-if="filter.groupName === filter.name">
            <v-expansion-panels multiple flat accordion hover tile class="pa-0 ma-0">
              <v-expansion-panel v-for="(subFilter,i) in filter.items" :key="'sf'+i" class="pb-0 ma-0">
                <v-expansion-panel-header class="pa-0 my-0 ml-1 pr-1" style="min-height: 36px">{{ subFilter.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-checkbox class="pl-0 ml-0 py-0 my-0" v-model="activeFilters" :label="subFilterItem.name"
                              v-for="subFilterItem in subFilter.items"
                              :value="subFilterItem"
                              :key="'sfi'+subFilterItem.id"
                              dense/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <template v-else>
            <v-checkbox class="py-0 my-0" v-model="activeFilters" :label="filterItem.name"
                        v-for="filterItem in filter.items"
                        :value="filterItem"
                        :key="filterItem.id"
                        dense/>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
  <!-- panelType !== "Panel" -->
  <v-card v-else max-width="500" flat class="mx-auto" style="border-radius: 0; border-right: 1px solid rgba(0, 0, 0, 0.05);">
    <v-expansion-panels v-if="filters.length > 0" v-model="panel" multiple flat accordion hover tile>
      <v-expansion-panel v-for="(filter, i) in filters" :key="'f'+i">
        <v-expansion-panel-header class="primary--text font-weight-bold pb-0 pt-2 ma-0" style="min-height: 40px">
          {{ groupHeaderName(filter) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-if="filter.groupName === filter.name">
            <v-expansion-panels multiple flat accordion hover tile class="pa-0 ma-0">
              <v-expansion-panel v-for="(subFilter,i) in filter.items" :key="'sf'+i" class="pb-0 ma-0">
                <v-expansion-panel-header class="pa-0 my-0 ml-1 pr-1" style="min-height: 36px">{{ subFilter.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-truncate text-no-wrap">
                  <v-checkbox class="pl-0 ml-0 py-0 my-0" v-model="activeFilters" :label="subFilterItem.name"
                              v-for="subFilterItem in subFilter.items"
                              :value="subFilterItem"
                              :key="'sfi'+subFilterItem.id"
                              dense/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <template v-else>
            <v-checkbox class="py-0 my-0" v-model="activeFilters"
                        v-for="filterItem in filter.items"
                        :value="filterItem"
                        :key="filterItem.id"
                        dense>
              <template v-slot:label><span class="truncate" :style="labelStyle">{{ filterItem.name }}</span></template>
            </v-checkbox>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
  </v-card>
</template>

<script>
import remoteApi from "../services/remoteApi"
import {mapState} from "vuex";
// import {debounce} from "lodash"

export default {
  props: ['componentWidth'],
  name: "AssetFilterSidebar",
  computed: {
    ...mapState({
      searchQueryValues: state => state.search.searchQuery.currentSearchQueryValues,
      config: state => state.config.server.portalUIConfiguration,
    }),
    labelStyle() {
      return {
        width: this.componentWidth - 80 + 'px'
      }
    },
  },
  data() {
    return {
      panelType: 'List',
      filters: [],
      panel: [],
      activePanel: '',
      activeFilters: []
    }
  },
  methods: {
    updateQueries() {
      this.$store.commit('search/SET_KEYWORD_SEARCH_VALUE', this.activeFilters)
    },
    groupHeaderName(filter){
      if(filter.translate){
        return this.$t(filter.name)
      }
      return filter.name
    }
  },
  watch: {
    activeFilters() {
      this.updateQueries()
    },
    //TODO move the active filters into the store, this is to strong coupled
    searchQueryValues(value) {
      if (value.length === 0) {
        this.activeFilters = []
      }
    }
  },
  created() {
    remoteApi.loadAssetFilters(this.$i18n.locale).then(response => {
      this.filters = response.items
      this.filters.forEach(f => {
        f.items.forEach(fi => fi.parent = f.id)
      })
      response.items.forEach((element, index) => {
        if (element.open) {
          this.panel.push(index)
        }
      })
    })
  }
}
</script>

<style>
.v-expansion-panel-content__wrap {
  padding-bottom: 0;
  /*padding-left: 14px;*/
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 54px;
}

.v-label {
  font-size: 14px;
}

.v-input--selection-controls.v-input {
  height: 28px;
}

/*.toolbar-title {*/
/*	color: inherit;*/
/*}*/
</style>